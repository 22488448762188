import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

export interface InternalLinkInt {
  _type: string;
  link: {
    _id: string;
    _type: "page";
    content: {
      main: {
        slug: {
          current: string;
        };
      };
    };
  };
}

export interface ExternalLinkInt {
  _type: string;
  link: string;
}

export const DeductivePageLink = (props: {
  internalLink?: InternalLinkInt;
  externalLink?: ExternalLinkInt;
  className?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onMouseOver?: () => void;
  children?: any;
}) => {
  const type = Boolean(props.internalLink) ? "internalLink" : "externalLink";
  const to = Boolean(props.internalLink)
    ? props.internalLink
    : props.externalLink;

  const pageLinkProps = {
    ...props,
    type,
    to,
  };

  return <PageLink {...pageLinkProps}>{props.children}</PageLink>;
};

export const PageLink = (props: {
  className?: string;
  to: any;
  type?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onMouseOver?: () => void;
  children?: any;
}) => {
  if (props.type === "externalLink") {
    return (
      <a
        className={props.className}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        onMouseOver={props.onMouseOver}
        href={props.to}
      >
        {props.children}
      </a>
    );
  }

  let to = props.to;

  if (props.type === "internalLink") {
    const slug = props.to.content.main.slug.current;
    switch (props.to._type) {
      case "page":
        to = slug === "home" ? `/` : `/${slug}/`;
        break;
      default:
        to = `/${slug}/`;
    }
  }

  return (
    <Link
      type={props.type}
      className={props.className}
      activeClassName="active"
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onMouseOver={props.onMouseOver}
      to={to}
    >
      {props.children}
    </Link>
  );
};

export const FileLink = ({
  file,
  title,
  subtitle,
}: {
  file: {
    asset: {
      url: string;
    };
  };
  title: string;
  subtitle?: string;
}) => {
  return (
    <a
      href={`${file.asset.url}`}
      download
      className="block bg-white rounded py-4 px-6 mb-6 text-ocean group"
      download
    >
      <div className="flex items-center">
        <FontAwesomeIcon icon={faFilePdf} className="mr-3" />
        <h5 className="font-sans group-hover:text-pinkcrayon transition duration-150">
          {title}
        </h5>
      </div>
      {subtitle && <span>{subtitle}</span>}
    </a>
  );
};
