import React, { useEffect } from "react"
import { SwitchTransition, Transition } from "react-transition-group"

import { Header } from "../components/header"
import { Footer } from "../components/footer"

const TRANSITION_DURATION = 400
const TRANSITION_STYLES = {
  default: {
    transition: `opacity ${TRANSITION_DURATION}ms ease-in-out`,
  },
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    opacity: 0,
  },
  exited: {
    opacity: 0,
  },
}

const Layout = ({ children, location }: { children: any }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      {/*
            Smooth transition credits to Ian Williams: https://github.com/dictions
          */}
      <div className="flex-1">
        {!/account/.test(location.pathname) ? (
          <SwitchTransition>
            <Transition
              key={location.pathname}
              mountOnEnter={true}
              unmountOnExit={true}
              appear={true}
              timeout={TRANSITION_DURATION}
            >
              {status => (
                <div
                  style={{
                    ...TRANSITION_STYLES.default,
                    ...TRANSITION_STYLES[status],
                  }}
                >
                  {children}
                </div>
              )}
            </Transition>
          </SwitchTransition>
        ) : (
          <div>
            {children}
            <Footer />
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default Layout
