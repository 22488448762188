import React, { useState } from "react";
import cx from "classnames";
import { StaticQuery, graphql } from "gatsby";
import { Announcement } from "./announcement";
import { Close, Hamburger, LogoHorizontal, DropdownArrow } from "./svg";
import { PageLink } from "./link";
import ctaStyles from "./cta.module.css";

const HeaderSubNav = ({ title, items, openFlyout, setOpenFlyout }) => {
  return (
    <div className="relative">
      <button
        className="flex items-center ml-auto hover:text-pinkcrayon leading-none"
        onClick={() => {
          openFlyout === title ? setOpenFlyout("") : setOpenFlyout(title);
        }}
      >
        <span>{title}</span>{" "}
        <DropdownArrow
          className={cx("ml-2 h-2", {
            "transform rotate-180": openFlyout === title,
          })}
        />
      </button>
      <ul
        className={cx(
          "md:absolute bg-white w-full px-4 pt-4 md:pt-6 pb-2 md:pb-3 md:w-56",
          {
            hidden: openFlyout !== title,
          }
        )}
      >
        {items.map(({ title, link, _type }) => (
          <li key={title} className="pb-3">
            <PageLink type={_type} to={link} onClick={() => setOpenFlyout("")}>
              {title}
            </PageLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

const HeaderLinkList = ({
  list,
  className,
  openFlyout,
  setOpenFlyout,
}: {
  className?: string;
  list: {
    title: string;
    items: array;
    lastCTA?: boolean;
  };
}) => {
  return (
    <ul className={`md:flex items-center ${className}`}>
      {list.items.map((data, i) => {
        const { title, link, _type } = data;
        return (
          <li
            key={title}
            className={`mb-3 md:mb-0 ${
              i !== list.items.length - 1 ? "md:pr-3 lg:pr-8" : ""
            }`}
          >
            {_type === "subNav" ? (
              <HeaderSubNav
                {...data}
                openFlyout={openFlyout}
                setOpenFlyout={setOpenFlyout}
              />
            ) : (
              <PageLink
                type={_type}
                to={link}
                className={cx({
                  [ctaStyles.primary]:
                    i === list.items.length - 1 && list.lastCTA,
                })}
              >
                {title}
              </PageLink>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export const Header = () => {
  const [isExpanded, expandNav] = useState(false);
  const [openFlyout, setOpenFlyout] = useState("");

  const linkedListProps = {
    openFlyout,
    setOpenFlyout,
  };
  return (
    <StaticQuery
      query={graphql`
        query HeaderQuery {
          sanityHeader {
            _rawHeaderLinksLeft(resolveReferences: { maxDepth: 10 })
            _rawHeaderLinksRight(resolveReferences: { maxDepth: 10 })
          }
        }
      `}
      render={(data) => (
        <>
          <Announcement />
          <div className="relative bg-white flex flex-wrap justify-between items-center py-3 px-4 sticky top-0 z-10 shadow-sm">
            <HeaderLinkList
              list={data.sanityHeader._rawHeaderLinksLeft}
              className="hidden md:block w-1/3"
              {...linkedListProps}
            />
            <PageLink to="/" className="w-full mx-10 md:mx-0 md:w-1/3 block">
              <LogoHorizontal className="h-16 lg:h-20 mx-auto" />
            </PageLink>
            <HeaderLinkList
              list={data.sanityHeader._rawHeaderLinksRight}
              className="hidden md:block w-1/3 justify-end"
              {...linkedListProps}
            />
            <button
              className="absolute right-0 mr-4 top-10 md:hidden"
              onClick={() => expandNav(!isExpanded)}
            >
              <Hamburger className={`${isExpanded ? "hidden" : "block"}`} />
              <Close className={`${isExpanded ? "block" : "hidden"}`} />
            </button>
            <div
              className={cx(
                "absolute bg-white right-0 left-0 md:hidden w-full p-4 top-mnav shadow-sm",
                {
                  hidden: !isExpanded,
                  block: isExpanded,
                }
              )}
            >
              <HeaderLinkList
                list={data.sanityHeader._rawHeaderLinksLeft}
                className="text-right"
                {...linkedListProps}
              />
              <HeaderLinkList
                list={data.sanityHeader._rawHeaderLinksRight}
                className="text-right"
                {...linkedListProps}
              />
            </div>
          </div>
        </>
      )}
    />
  );
};
