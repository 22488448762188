import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { PageLink } from "./link"

export const Announcement = () => (
  <StaticQuery
    query={graphql`
      query AnnouncementQuery {
        sanityHeader {
          _rawAnnouncement
          location
          _rawLocationLink(resolveReferences: { maxDepth: 10 })
        }
      }
    `}
    render={data => (
      <div className="bg-ocean text-white px-2 md:px-4 py-2 font-sans text-sm flex flex-wrap justify-between items-center c-single-line c-dark-bg">
        <BlockContent blocks={data.sanityHeader._rawAnnouncement} />
        <p className="hidden md:block">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
          <PageLink
            to={data.sanityHeader._rawLocationLink}
            type="internalLink"
            className="hover:text-white"
          >
            {data.sanityHeader.location}
          </PageLink>
        </p>
      </div>
    )}
  />
)
