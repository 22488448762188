import React from "react";
import { StaticQuery, graphql } from "gatsby";

import { Butterfly, LogoText } from "./svg";
import { PageLink } from "./link";

export const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        sanityFooter {
          _rawFooterLinkLists(resolveReferences: { maxDepth: 10 })
        }
        sanityGlobal {
          contact {
            addressLine1
            addressLine2
            city
            email
            phoneNumber
            state
            zip
          }
        }
      }
    `}
    render={(data) => {
      const {
        addressLine1,
        addressLine2,
        city,
        email,
        phoneNumber,
        state,
        zip,
      } = data.sanityGlobal.contact;
      return (
        <div className="bg-evergreen text-white px-6 pb-24 mt-16">
          <Butterfly className="h-28 mx-auto -mt-16 z-10" />
          <div className="flex justify-between flex-wrap pt-8">
            <div className="flex flex-col md:flex-row w-full md:w-auto">
              <div className="md:pr-20 w-full md:w-auto mb-8">
                <LogoText className="w-44 mx-auto md:mx-0" />
              </div>

              <div className="flex flex-col md:flex-row mb-8 justify-center">
                {data.sanityFooter._rawFooterLinkLists.map(
                  ({ items }, index) => (
                    <ul
                      key={index}
                      className="block md:pr-16 text-center md:text-left"
                    >
                      {items.map(({ _type, link, title }, index) => (
                        <li className="pb-2" key={index}>
                          {_type === "subNav" ? (
                            <>
                              {
                                "This is a subnav. You can't use a subnav for the footer"
                              }
                            </>
                          ) : (
                            <PageLink
                              type={_type}
                              to={link}
                              className="hover:underline hover:text-white transition-all duration-150"
                            >
                              {title}
                            </PageLink>
                          )}
                        </li>
                      ))}
                    </ul>
                  )
                )}
              </div>
            </div>
            <div className="text-center md:text-left w-full md:w-auto">
              <h5 className="text-white mb-4">Contact</h5>
              <div className="flex flex-col md:flex-row">
                <div className="md:pr-16 mb-8">
                  <p className="mb-0">{addressLine1}</p>
                  <p className="mb-0">{addressLine2}</p>
                  <p className="mb-0">
                    {city}, {state} {zip}
                  </p>
                </div>
                <div>
                  <p className="mb-0">
                    <PageLink
                      type="externalLink"
                      to={`mailto:${email}`}
                      className="hover:underline hover:text-white transition-all duration-150"
                    >
                      {email}
                    </PageLink>
                  </p>
                  <p className="mb-0">
                    <PageLink
                      type="externalLink"
                      to={`tel:${phoneNumber}`}
                      className="hover:underline hover:text-white transition-all duration-150"
                    >
                      {phoneNumber}
                    </PageLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }}
  />
);
