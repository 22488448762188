// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-parents-tsx": () => import("./../../../src/pages/parents.tsx" /* webpackChunkName: "component---src-pages-parents-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-blogpost-tsx": () => import("./../../../src/templates/blogpost.tsx" /* webpackChunkName: "component---src-templates-blogpost-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-parent-form-tsx": () => import("./../../../src/templates/parentForm.tsx" /* webpackChunkName: "component---src-templates-parent-form-tsx" */),
  "component---src-templates-parent-image-gallery-tsx": () => import("./../../../src/templates/parentImageGallery.tsx" /* webpackChunkName: "component---src-templates-parent-image-gallery-tsx" */),
  "component---src-templates-parent-newsletter-tsx": () => import("./../../../src/templates/parentNewsletter.tsx" /* webpackChunkName: "component---src-templates-parent-newsletter-tsx" */)
}

