import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { setSessionPassword } from "@mkitio/gatsby-theme-password-protect/src/utils/utils";

import { Header } from "../../../components/header";
import { Footer } from "../../../components/footer";
import btnStyles from "../../../components/cta.module.css";

const PasswordProtect = () => {
  const [password, setPassword] = useState("");

  const data = useStaticQuery(graphql`
    query ParentPasswordPageQuery {
      sanityParentGlobal {
        title
        passwordPageText
      }
    }
  `);

  const { title, passwordPageText } = data.sanityParentGlobal;

  const onSubmit = (event) => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <div className="bg-cream flex flex-col min-h-screen">
      <Header />
      <h1 className="max-w-4xl mx-auto mt-8">{title}</h1>
      <p className="max-w-4xl mx-auto">{passwordPageText}</p>
      <form onSubmit={onSubmit} className="flex max-w-4xl mx-auto my-10">
        <input
          name="password"
          type="password"
          value={password}
          className="px-6 py-4 sm:w-72"
          placeholder="Enter Password"
          onChange={(event) => setPassword(event.target.value)}
        />

        <button className={btnStyles.primary} type="submit">
          Enter
        </button>
      </form>
      <Footer />
    </div>
  );
};

export default PasswordProtect;
